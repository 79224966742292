import axios from 'axios';

let auth_token = window.localStorage.getItem('auth_token');

let baseUrl = (window.location.hostname === 'localhost') ?
  `${window.location.protocol}//${window.location.hostname}:8021/api`
  :
  `${window.location.protocol}//${window.location.hostname.replace('admin.', 'api.')}/api`

const api = axios.create({
  baseURL: baseUrl,

  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${auth_token}`
  },
});

api.defaults.headers.get['Access-Control-Allow-Origin'] = '*'
api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';

export {
  api
}

