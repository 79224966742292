<template>
  <div>
    <div class="auth-container" v-if="!sessionStatus && isChecked">
      <div class="row">
        <!--        <slot name="loading">
                  <i class="fas fa-spinner fa-spin"></i>
                </slot>-->
        <card :title="`Auth`">
          <div class="col-md pr-md-1">
            <base-input
              placeholder="Email"
              v-model="user.email"
            >
            </base-input>
          </div>
          <div class="col-md pr-md-1">
            <base-input
              :type="`password`"
              placeholder="Password"
              v-model="user.password"
            >
            </base-input>
          </div>
          <div class="col-md pr-md-1 auth-container__button">
            <base-button @click="getSession" type="primary" fill>Log in
            </base-button>
          </div>
        </card>
      </div>
    </div>
    <router-view v-if="sessionStatus" :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import {api} from "@/api";

export default {
  data() {
    return {
      sessionStatus: false,
      isChecked: false,
      userID: '',
      user: {
        email: '',
        password: ''
      }
    }
  },
  mounted() {
    this.getSessionStatus()
    this.$watch('$route', this.disableRTL, { immediate: true });
    this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
  },
  methods: {
    async getSession() {
      let response
      try {
        response = await api.post('/users/login', this.user)
        window.localStorage.setItem('auth_token', response.data.token)
        location.reload()
      } catch (e) {
        console.debug(e)
      }
    },
    async getSessionStatus() {
      let response
      try {
        response = await api.get('/whoAmI')
        this.userID = response.data
        if (this.userID.length && this.userID === '19d9b751-ac6a-48d5-93c9-b2876e1f2daf') {
          this.sessionStatus = true
          this.isChecked = true
        }
      } catch (e) {
        this.sessionStatus = false
        this.isChecked = true
      }

    },
    disableRTL() {
      if (!this.$rtl.isRTL) {
        this.$rtl.disableRTL();
      }
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName('html')[0];
      root.classList.toggle('nav-open');
    }
  }
};
</script>

<style lang="scss">
.auth-container {
  margin: 20vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-container__button {
  display: flex;
  justify-content: center;
}

.col-md {
  padding-left: 0 !important;
}

.el-table__cell {
  background-color: transparent !important;
}

.el-table table thead th {
  background-color: transparent !important;
}
.el-table {
  background-color: transparent !important;
}

</style>
